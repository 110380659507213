<template>
  <div>
    <div class="row pt-2">
      <SchedJobParam @scheduleChange="handleScheduleChange" />
      <div class="col-4">
        <a-button
          v-if="canGenerate && !generated"
          icon="rocket"
          @click="handleGenerateSched"
          :disabled="!canGenerate"
          :type="canGenerate ? 'primary' : ''"
          :loading="loadingGenerateButton"
          style="float: right; width: 200px"
          :class="canGenerate ? 'success-btn' : ''"
          >Generate
        </a-button>
        <a-tooltip v-if="!canGenerate && !generated">
          <template slot="title">
            {{ $t("smartSchedule.fillAllClassroomsToGenerate") }}
          </template>
          <a-badge style="float: right; width: 200px">
            <a-icon
              slot="count"
              size="large"
              style="color: #52c41a"
              type="question-circle"
            />

            <a-tooltip>
              <template slot="title">
                {{ $t("smartSchedule.fillAllClassroomsToGenerate") }}
              </template>
              <a-button
                icon="rocket"
                :disabled="true"
                style="float: right; width: 200px"
                >Generate
              </a-button>
            </a-tooltip>
          </a-badge>
        </a-tooltip>
      </div>
    </div>
    <a-divider></a-divider>
    <a-col class="mt-5" v-if="loadingGenerealParams" :span="16" :offset="11">
      <a-spin size="large" />
    </a-col>
    <div v-if="!loadingGenerealParams && selectedSchedule">
      <label>{{ $t("bulletin.listeClasses") }}</label>
      <a-select
        :placeholder="$t('emploi.listeClasses')"
        show-search
        option-filter-prop="children"
        :filter-option="filterOptionGroup"
        style="width: 300px; margin-left: 10px"
        @change="
          (val) => {
            handleClassChange(val);
          }
        "
      >
        <a-select-opt-group
          v-for="level in classesByLevel"
          :key="level.level"
          :label="levelSelectData[level.level]"
        >
          <a-select-option
            v-for="classe in level.classes"
            :key="classe._id"
            :value="classe._id"
            >{{ classe.name }}</a-select-option
          >
        </a-select-opt-group>
      </a-select>

      <div class="mt-3">
        <a-table
          :loading="tableLeading"
          :rowKey="'_id'"
          :columns="columns"
          :data-source="data"
          :pagination="true"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="teacher" slot-scope="text, record">
            <a-select
              show-search
              :placeholder="$t('error.selectEnseignant')"
              option-filter-prop="children"
              :filter-option="filterOption"
              style="width: 400px"
              :value="getDefaultTeacher(record)"
              @change="
                (val) => {
                  saveTeacher(val, record);
                }
              "
            >
              <a-select-option
                v-for="teacher in teachers"
                :key="teacher._id"
                :value="teacher._id"
              >
                {{ teacher.fullName }}
              </a-select-option>
            </a-select>
          </template>
          <template slot="weeklyRate" slot-scope="text, record">
            <a-tag
              color="blue"
              v-if="
                getDefaultTeacher(record) &&
                teachersWeeklyRates[getDefaultTeacher(record)]
              "
              >{{
                formatWeeklyRate(teachersWeeklyRates[getDefaultTeacher(record)])
              }}</a-tag
            >
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import SchedJobParam from "@/views/parametrage/smartSchedule/components/schedJodParam/index.vue";

export default {
  name: "subjects",
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  components: {
    SchedJobParam,
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - General",
    });

    apiClient
      .post("/teachers/filter", {
        query: { status: "active" },
      })
      .then((res) => {
        this.teachers = res.data.map((d) => {
          return {
            _id: d._id,
            fullName: d.employee.firstName + " " + d.employee.lastName,
          };
        });
      });
  },
  data() {
    return {
      generated: false,
      canGenerate: false,
      loadingGenerateButton: true,
      loadingGenerealParams: false,
      teacherClassParams: [],
      teachersWeeklyRates: {},
      teachers: [],
      tableLeading: false,
      data: [],
      addScheduleLoading: false,
      loadingGenerealParams: false,
      loadingSave: false,
      visibleModal: false,
      form: this.$form.createForm(this),
      selectedSchedule: undefined,
      moment,
      classesByLevel: [],
      classes: [],
      selectedClasse: null,
      selectedLevel: null,
      levelSelectData: {
        "-2": this.$t("niveau.niveauPre"),
        "-1": this.$t("niveau.niveau0"),
        1: this.$t("niveau.niveau1"),
        2: this.$t("niveau.niveau2"),
        3: this.$t("niveau.niveau3"),
        4: this.$t("niveau.niveau4"),
        5: this.$t("niveau.niveau5"),
        6: this.$t("niveau.niveau6"),
        7: this.$t("niveau.niveau7"),
        8: this.$t("niveau.niveau8"),
        9: this.$t("niveau.niveau9"),
        10: this.$t("niveau.niveau10"),
        11: this.$t("niveau.niveau11"),
        12: this.$t("niveau.niveau12"),
        13: this.$t("niveau.niveau13"),
      },
      columns: [
        {
          title: this.$t("smartSchedule.subject"),
          dataIndex: "name",
          sorter: true,
          key: "subject",
          sorter: (a, b) => a.type.length - b.type.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            customRender: "subject",
          },
        },
        {
          title: this.$t("actualite.teachers"),
          dataIndex: "teacher",
          key: "teacher",
          scopedSlots: {
            customRender: "teacher",
          },
        },
        {
          title: this.$t("smartSchedule.teachersWeeklyRate"),
          dataIndex: "weeklyRate",
          key: "weeklyRate",
          scopedSlots: {
            customRender: "weeklyRate",
          },
        },
      ],
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterOptionGroup(input, option) {
      if (option.componentOptions.children[0].text)
        return (
          option.componentOptions.children[0].text
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        );
      return false;
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    async handleScheduleChange(val, schedule) {
      this.loadingGenerealParams = true;
      this.selectedSchedule = val;
      this.selectedClasse = null;

      this.classes = [];
      this.classesByLevel = [];
      this.data = [];

      this.generated = false;//schedule.status != "init";

      this.checkCanGenerate();

      await apiClient
        .post("/classrooms/filter", {
          query: {
            status: "active",
          },
          aggregation: {
            $project: {
              _id: 1,
              name: 1,
              level: 1,
            },
          },
        })
        .then((res) => {
          this.classes = res.data;
          const classesByLevel = {};
          res.data.forEach((classe) => {
            if (!classesByLevel[classe.level]) {
              classesByLevel[classe.level] = [];
            }
            classesByLevel[classe.level].push(classe);
          });

          this.classesByLevel = Object.keys(classesByLevel).map((level) => {
            return {
              level,
              classes: classesByLevel[level],
            };
          });

          this.classesByLevel.sort((a, b) => {
            return a.level - b.level;
          });
        });

      this.loadingGenerealParams = false;
    },
    async handleClassChange(val) {
      const classe = this.classes.find((classe) => {
        return classe._id === val;
      });

      this.tableLeading = true;

      this.selectedClasse = classe._id;
      this.selectedLevel = classe.level;

      await apiClient
        .post("/smartSchedule/teacherClassParam/filter", {
          query: {
            schedJobParam: this.selectedSchedule,
            classeRoom: this.selectedClasse,
          },
        })
        .then(({ data }) => {
          this.teacherClassParams = data;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.ajout"));
        });

      await this.getSubjects();

      await this.getTeachersWeeklyRates();

      this.tableLeading = false;
    },
    async getSubjects() {
      this.data = [];
      await apiClient
        .post("/smartSchedule/subjectsSchedParam/filter", {
          query: {
            level: this.selectedLevel,
            schedJobParam: this.selectedSchedule,
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            res.data[0].subjects.forEach((s) => {
              this.data.push({
                key: s.subject._id,
                name: s.name,
                type: "S",
              });
            });
            res.data[0].modules.forEach((s) => {
              this.data.push({
                key: s.module._id,
                name: s.name,
                type: "MP",
              });
            });
            res.data[0].modulesSpecific.forEach((s) => {
              this.data.push({
                key: s.moduleSpecific._id,
                name: s.name,
                type: "MS",
              });
            });
          } else {
            this.$message.warning(this.$t("error.aucMatiere"));
          }
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
        });
    },
    async saveTeacher(val, record) {
      const teacherParm = this.teacherClassParams.find((t) => {
        return (
          (t.type == "S" && t.subject._id == record.key) ||
          (t.type == "MP" && t.module._id == record.key) ||
          (t.type == "MS" && t.moduleSpecific._id == record.key)
        );
      });

      this.tableLeading = true;

      const data = {
        schedJobParam: this.selectedSchedule,
        classeRoom: this.selectedClasse,
        teacher: val,
        type: record.type,
      };

      if (record.type == "S") {
        data.subject = record.key;
        data.module = null;
        data.moduleSpecific = null;
      } else if (record.type == "MP") {
        data.module = record.key;
        data.subject = null;
        data.moduleSpecific = null;
      } else if (record.type == "MS") {
        data.moduleSpecific = record.key;
        data.subject = null;
        data.module = null;
      }

      if (!teacherParm)
        await apiClient
          .put("/smartSchedule/teacherClassParam", {
            data,
          })
          .then(({ data }) => {
            this.teacherClassParams.push(data);
            this.$message.success(
              this.$t("smartSchedule.teacherAffectedSuccess")
            );
          })
          .catch((e) =>
            this.$message.error(this.$t("error.impoModifiMatieres"))
          );
      else
        await apiClient
          .patch("/smartSchedule/teacherClassParam/" + teacherParm._id, {
            data,
          })
          .then(() => {
            this.$message.success(
              this.$t("smartSchedule.teacherAffectedSuccess")
            );

            teacherParm.key = val;
          })
          .catch((e) =>
            this.$message.error(this.$t("error.impoModifiMatieres"))
          );

      await apiClient
        .post("/smartSchedule/teacherClassParam/filter", {
          query: {
            schedJobParam: this.selectedSchedule,
            classeRoom: this.selectedClasse,
          },
        })
        .then(({ data }) => {
          this.teacherClassParams = data;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.ajout"));
        });

      await this.getTeachersWeeklyRates();

      this.checkCanGenerate();
      this.tableLeading = false;
    },
    getDefaultTeacher(record) {
      const teacher = this.teacherClassParams.find((t) => {
        return (
          (t.type == "S" && t.subject._id == record.key) ||
          (t.type == "MP" && t.module._id == record.key) ||
          (t.type == "MS" && t.moduleSpecific._id == record.key)
        );
      });
      if (teacher) return teacher.teacher._id;

      return undefined;
    },
    async getTeachersWeeklyRates() {
      await apiClient
        .get(
          "/smartSchedule/teacherClassParam/teachersWeeklyRate/" +
            this.selectedSchedule
        )
        .then(({ data }) => {
          this.teachersWeeklyRates = data;
        });
    },
    formatWeeklyRate(rate) {
      let hours = Math.floor(rate / 60);
      let minutes = rate % 60;
      if (minutes < 10) minutes = "0" + minutes;
      if (hours < 10) hours = "0" + hours;
      return hours + "h" + minutes;
    },
    async checkCanGenerate() {
      this.canGenerate = false;
      this.loadingGenerateButton = true;
      await apiClient
        .get(
          "/smartSchedule/parametrage/checkGeneration/" + this.selectedSchedule
        )
        .then(({ data }) => {
          this.canGenerate = data.ableToGenerate;
        })
        .finally(() => {
          this.loadingGenerateButton = false;
        });
    },
    async handleGenerateSched() {
      this.loadingGenerateButton = true;

      await apiClient
        .post(
          "/smartSchedule/parametrage/startGeneration/" + this.selectedSchedule,
          {
            data: {},
          }
        )
        .then(() => {
          this.$router.push({
            path: "/smart-schedule",
          });
        })
        .finally(() => {
          this.loadingGenerateButton = false;
        });
    },
  },
};
</script>

<style scoped>
.success-btn {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}
</style>
